import { createContext, ReactNode, useEffect, useState } from 'react';
import { IProductRow } from '../pages/consigned/order/dtos/IConOrderDTO';
import {
  emptyOrder,
  emptyOrderBody,
  emptyOrderHeader,
  emptyOrderTransporter,
  IOrderBody,
  IOrderDTO,
  IOrderHeader,
  IOrderPayment,
  IOrderTransporter,
  IProposal,
} from '../pages/order/Create/dtos/IOrderDTO';
import api from '../services/api';
import {
  emptyParametersStock,
  IParametersStock,
} from '../pages/parameters/dtos/IParametersStock';
import { ICommissioned } from '../pages/comissioned/dtos/ICommissioned';
import { INatureOperation } from '../pages/nature-operation/dtos/INatureOperation';
import { ITablePrice } from '../pages/product/dtos/IProductDTO';
import { IStore } from '../pages/store/dtos';
import { IPaymentCondition } from '../pages/paymentCondition/dtos/IPaymentCondition';
import { ITransporter } from '../pages/person/dtos/ITransporter';
import PerfectDivision from '../utils/perfectDivision';
import { v4, validate } from 'uuid';
import {
  addDays,
  addHours,
  differenceInDays,
  isBefore,
  startOfDay,
} from 'date-fns';

interface IContextData {
  order: IOrderDTO;
  setOrder: (order: IOrderDTO) => void;
  setOrderHeader: (orderHeader: IOrderHeader) => void;
  setOrderBody: (orderBody: IOrderBody[]) => void;
  setOrderTransporter: (orderTransporter: IOrderTransporter) => void;
  setOrderPayments: (orderPayments: Partial<IOrderPayment>[]) => void;
  clearData: () => void;
  clearOptions: () => void;
  fetchProposal: (id: string) => void;
  loadNextOrderSequence: () => void;

  addProduct: (item: Partial<IProductRow>) => void;
  remProduct: (id: string) => void;

  addPayments: (item: Partial<IOrderPayment>[]) => void;
  remPayments: (groupId: string) => void;

  loadContext: boolean;

  /** Parte separada para salvar as opções (comissionados, nat
   * opes, tabelas...) */
  commissioneds?: ICommissioned[];
  natOpes?: INatureOperation[];
  tablePrices?: ITablePrice[];
  stockParams?: IParametersStock;
  salesParams?: any;
  stores?: IStore[];
  paymentConditions?: IPaymentCondition[];
  transporters?: ITransporter[];
  proposals?: IProposal[];
}

export const OrderContext = createContext<IContextData | undefined>(undefined);

export function OrderProvider({ children }: { children: ReactNode }) {
  const [loadContext, setLoadContext] = useState<boolean>(false);

  const [order, setOrderState] = useState<IOrderDTO>(emptyOrder);

  const today = new Date();

  const [isInitializated, setIsInitializated] = useState<boolean>(false);

  const [natOpes, setNatOpes] = useState<INatureOperation[]>([]);
  const [commissioneds, setCommissioneds] = useState<ICommissioned[]>([]);
  const [tablePrices, setTablePrices] = useState<ITablePrice[]>([]);
  const [stockParams, setStockParams] =
    useState<IParametersStock>(emptyParametersStock);
  const [stores, setStores] = useState<IStore[]>([]);
  const [paymentConditions, setPaymentConditions] = useState<
    IPaymentCondition[]
  >([]);
  const [transporters, setTransporters] = useState<ITransporter[]>([]);
  const [proposals, setProposals] = useState<IProposal[]>([]);
  const [salesParams, setSalesParams] = useState<any>();

  const clearOptions = () => {
    setIsInitializated(false);

    setNatOpes([]);
    setCommissioneds([]);
    setStores([]);
    setTablePrices([]);
    setPaymentConditions([]);
    setProposals([]);
    setSalesParams({});
    setStockParams(emptyParametersStock);
    setTransporters([]);
  };

  const initializeData = async () => {
    if (!isInitializated) {
      setLoadContext(true);

      await Promise.all([
        loadNatOpes(),
        loadCommissioneds(),
        loadStores(),
        loadTablePrices(),
        loadProposals(),
        loadTransporters(),
        loadStockParams(),
        loadPaymentConditions(),
      ]).finally(() => setLoadContext(false));

      if (!isInitializated) setIsInitializated(true);
    }
  };

  const loadNextOrderSequence = async () => {
    setLoadContext(true);

    initializeData();

    await api
      .get('/params/salles/default')
      .then(async ({ data: salesParams }) => {
        if (salesParams) {
          setSalesParams(salesParams);

          await api.get('/order-header/last-id').then(({ data }) => {
            if (data) {
              let obs = '';

              const natOpe = natOpes?.find(
                n => n.id === salesParams.natureza_operacao.id,
              );
              if (natOpe) {
                obs = natOpe.info_adicional;
              }

              setOrder({
                ...emptyOrder,
                orderHeader: {
                  ...emptyOrderHeader,
                  order_id: v4(),
                  sequence: Number(data),
                  commissioned_id:
                    salesParams.commissioned.id !== null
                      ? salesParams.commissioned.id
                      : commissioneds[0].id,
                  store_id: salesParams.store.id,
                  natureza_operacao_id: salesParams.natureza_operacao.id,
                  obs,
                },
              });
            }
          });
        }
      })
      .finally(() => setLoadContext(false));
  };

  const loadNatOpes = async () => {
    await api
      .post('/naturezas-operacao/list/short', {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida', 'troca', 'devolucao'],
        },
      })
      .then(({ data }) => {
        if (data) {
          setNatOpes(data);
        }
      });
  };

  const loadCommissioneds = async () => {
    await api.get('/commissioneds').then(({ data }) => {
      if (data) {
        setCommissioneds(data);
      }
    });
  };

  const loadStores = async () => {
    await api.get('/stores').then(({ data }) => {
      if (data) {
        setStores(data);
      }
    });
  };

  const loadTablePrices = async () => {
    await api
      .post('/table-prices/list', {
        pagination: {
          page: 1,
          perPage: 100,
          status: ['A'],
        },
      })
      .then(({ data }) => {
        if (data) {
          setTablePrices(data);
        }
      });
  };

  const loadProposals = async () => {
    await api
      .get('/order-header/order/src/proposal?search=')
      .then(({ data }) => {
        if (data) {
          setProposals(data);
        }
      });
  };

  const loadTransporters = async () => {
    await api
      .post('/persons/transporters', {
        pagination: {
          page: 1,
          perPage: 100,
          status: ['A'],
        },
      })
      .then(({ data }) => {
        if (data) {
          setTransporters(data);
        }
      });
  };

  const loadStockParams = async () => {
    await api.get('/params/stock/default').then(({ data }) => {
      if (data) {
        setStockParams(data);
      }
    });
  };

  const loadPaymentConditions = async () => {
    await api.get('/payments-condition').then(({ data }) => {
      if (data) {
        setPaymentConditions(data);
      }
    });
  };

  const setOrder = (item: IOrderDTO) => {
    setOrderState({ ...item });
  };

  const setOrderHeader = (item: IOrderHeader) => {
    setOrderState({ ...order, orderHeader: item });
  };

  const setOrderTransporter = (item: IOrderTransporter) => {
    setOrderState({ ...order, orderTransporter: item });
  };

  const setOrderBody = (item: IOrderBody[]) => {
    setOrderState({ ...order, orderBody: item });
  };

  const setOrderPayments = (item: Partial<IOrderPayment>[]) => {
    setOrderState({ ...order, orderPayments: item });
  };

  const clearData = () => {
    setOrderState({
      ...emptyOrder,
      orderHeader: {
        ...emptyOrderHeader,
        order_id: v4(),
      },
    });
  };

  const fetchProposal = async (id: string) => {
    setLoadContext(true);

    initializeData();

    await api
      .get(`/order-header/proposal/id/${id}`)
      .then(async ({ data }) => {
        const orderPayments: IOrderPayment[] = [];

        if (data.payment_condition_id && validate(data.payment_condition_id)) {
          setLoadContext(true);
          await api
            .get(`/payments-condition/${data.payment_condition_id}`)
            .then(({ data: paymentCondition }) => {
              if (paymentCondition) {
                const parcels = PerfectDivision(
                  Number(data.order_total),
                  Number(paymentCondition.qnt_parcelas),
                );

                let index = 0;
                const groupId = v4();
                for (const parcel of parcels) {
                  orderPayments.push({
                    description: `${paymentCondition.title} - ${index + 1}/${
                      paymentCondition.qnt_parcelas
                    }`,
                    value_parcel: Number(parcel),
                    due_date:
                      Number(paymentCondition.variacao_dias) === 1 ||
                      Number(paymentCondition.variacao_dias) === 0
                        ? today
                        : addDays(
                            new Date(data.order_date),
                            Number(paymentCondition.variacao_dias) *
                              (index + 1),
                          ),
                    id: v4(),
                    group_id: groupId,
                    parcel: index + 1,
                    payment_condition: paymentCondition,
                    payment_condition_id: paymentCondition.id,
                  });
                  index++;
                }
              }
            });
        } else {
          if (data.orderPayments && data.orderPayments.length > 0) {
            data.orderPayments.forEach((p: any) => {
              orderPayments.push({
                ...p,
                value_parcel: Number(p.value),
                description: `${p.paymentCondition.title} - ${p.parcel}/${p.paymentCondition.qnt_parcelas}`,
              });
            });
          }
        }

        setOrderState({
          orderHeader: {
            ...emptyOrderHeader,
            commissioned_id: data.commissioned_id,
            store_id: data.store_id,
            order_id: data.id,
            customer_id: data.customer_id,
            sequence: Number(data.order),
            customer: {
              value: data.customer_id,
              label: data.customer.name,
            },
            natureza_operacao_id: data.natureza_operacao_id,
            doc_key: data.doc_key,
            obs: data.obs,
            order_total: Number(data.order_total),
            order_date: addHours(new Date(data.order_date), 3),
            other_expenses: Number(data.other_expenses),
          },
          orderTransporter:
            data.transporter && data.transporter !== null
              ? {
                  ...data.transporter,
                  delivery_date:
                    data.transporter &&
                    data.transporter.delivery_date &&
                    data.transporter.delivery_date !== null &&
                    // Redefine data de entrega para hoje caso esteja para
                    // antes disso
                    differenceInDays(
                      addHours(new Date(data.transporter.delivery_date), 3),
                      today,
                    ) >= 0
                      ? addHours(new Date(data.transporter.delivery_date), 3)
                      : new Date(),
                }
              : emptyOrderTransporter,
          orderBody: data.orderBodyByOrderHeader.map(
            (i: any, index: number) => {
              const _lucre =
                i.product.prices[0].vlr_venda - i.product.cost_billing;
              const _lucreAliq = (_lucre * 100) / i.product.prices[0].vlr_venda;
              return {
                id: i.id,
                position: index,
                stock: i.product.stock,
                table_price_id: i.table_price_id,
                table_price_description: i.tablePrice.title,
                product_id: i.product_id,
                product_description: i.product.title,
                value: i.product_id,
                label: i.product.title,
                lucre: _lucre,
                lucre_aliq: _lucreAliq,
                qnt: i.qnt,
                qnt_out: i.product.decimalHouses[0]?.qnt_out || 2,
                value_out: i.product.decimalHouses[0]?.price_out || 2,
                vlr_unit: i.value_unit,
                adic: i.fit_additional,
                adic_aliq: 0,
                desc: i.fit_descount,
                desc_aliq: 0,
                vlr_liq: i.value_unit_liquid,
                ali_juros: i.ali_juros,
                inserted_at: i.product_insert,
                juros: i.juros,
                obs: i.obs,
                price_out: i.product.decimalHouses[0].price_out || 2,
                sku: i.product.sku,
                tt_adic: i.tt_adic,
                tt_desc: i.tt_desc,
                tt_juros: i.tt_juros,
                tt_unit: i.tt_unit,
                vlr_liq_total: i.value_total_liquid,
                image_url:
                  i.product.productImages && i.product.productImages.length > 0
                    ? i.product.productImages[0].image_url
                    : '',
              } as IOrderBody;
            },
          ),
          orderPayments,
        });
      })
      .finally(() => setLoadContext(false));
  };

  const addProduct = (item: Partial<IProductRow>) => {
    const product: IOrderBody = {
      ...emptyOrderBody,
      ...item,
    };

    setOrderBody([...order.orderBody.filter(i => i.id !== item.id), product]);
  };

  const remProduct = (id: string) => {
    setOrderBody([...order.orderBody.filter(i => i.id !== id)]);
  };

  const addPayments = (item: Partial<IOrderPayment>[]) => {
    setOrderPayments([...order.orderPayments, ...item]);
  };

  const remPayments = (groupId: string) => {
    setOrderPayments([
      ...order.orderPayments.filter(i => i.group_id !== groupId),
    ]);
  };

  return (
    <OrderContext.Provider
      value={{
        order,
        loadContext,
        commissioneds,
        natOpes,
        tablePrices,
        stockParams,
        salesParams,
        stores,
        paymentConditions,
        transporters,
        proposals,
        setOrder,
        setOrderHeader,
        setOrderBody,
        setOrderPayments,
        setOrderTransporter,
        clearData,
        clearOptions,
        loadNextOrderSequence,
        fetchProposal,
        addProduct,
        remProduct,
        addPayments,
        remPayments,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}
